<template>
  <div class='financials'>
    <b-container>
      <b-row class="financials__row flex-column-reverse flex-lg-row">
        <b-col class="col-12 col-lg-7 text-start financials__left">
          <h1 class="heading">Zonnepanelen financieel aantrekkelijk</h1>
          <p class="paragraph">
            Wist je dat het financieel enorm aantrekkelijk is om zonnepanelen te plaatsen? Met een levensduur van
            gemiddeld <span>
              25 jaar
            </span> start je al met verdienen na een periode van gemiddeld 6 jaar!
          </p>
          <p class="paragraph">
            Wereldwijd zijn de <span>prijzen gezakt en historisch laag</span> op dit moment.
            Tegelijkertijd kampen we
            met toenemende
            onzekerheid als het gaat om energieprijzen.<span> Bespaar nu </span> en wordt minder
            afhankelijk van de fluctuerende
            energietarieven.
          </p>
          <p class="paragraph">
            Jouw woning stijgt in waarde met zonnepanelen op het dak! <span> Jouw investering is daarom altijd
              interessant.</span>
          </p>
          <h3 class="heading_2">
            Ontvang aantrekkelijke subsidies
          </h3>
          <b-card class="financial_card">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M32 8.5H29.5V6C29.5 2.96 27.04 0.5 24 0.5H16C12.96 0.5 10.5 2.96 10.5 6V8.5H8C3.86 8.5 0.5 11.86 0.5 16V32C0.5 36.14 3.86 39.5 8 39.5H32C36.14 39.5 39.5 36.14 39.5 32V16C39.5 11.86 36.14 8.5 32 8.5ZM13.5 6C13.5 4.62 14.62 3.5 16 3.5H24C25.38 3.5 26.5 4.62 26.5 6V8.5H13.5V6ZM36.5 32C36.5 34.48 34.48 36.5 32 36.5H8C5.52 36.5 3.5 34.48 3.5 32V16C3.5 13.52 5.52 11.5 8 11.5H32C34.48 11.5 36.5 13.52 36.5 16V32Z"
                fill="#FEA700" />
              <path
                d="M17.5 19C17.5 17.62 16.38 16.5 15 16.5C13.62 16.5 12.5 17.62 12.5 19C12.5 20.38 13.62 21.5 15 21.5C16.38 21.5 17.5 20.38 17.5 19ZM14.5 19C14.5 18.72 14.72 18.5 15 18.5C15.28 18.5 15.5 18.72 15.5 19C15.5 19.56 14.5 19.56 14.5 19Z"
                fill="#FEA700" />
              <path
                d="M25 26.5C23.62 26.5 22.5 27.62 22.5 29C22.5 30.38 23.62 31.5 25 31.5C26.38 31.5 27.5 30.38 27.5 29C27.5 27.62 26.38 26.5 25 26.5ZM24.5 29C24.5 28.72 24.72 28.5 25 28.5C25.28 28.5 25.5 28.72 25.5 29C25.5 29.56 24.5 29.56 24.5 29Z"
                fill="#FEA700" />
              <path
                d="M25.06 18.94C24.48 18.36 23.52 18.36 22.94 18.94L14.94 26.94C14.36 27.52 14.36 28.48 14.94 29.06C15.24 29.36 15.62 29.5 16 29.5C16.38 29.5 16.76 29.36 17.06 29.06L25.06 21.06C25.64 20.48 25.64 19.52 25.06 18.94Z"
                fill="#FEA700" />
            </svg>
            <b-card-title>
              BTW-vrijstelling (korting van 21%)
            </b-card-title>
          </b-card>
          <b-card class="financial_card">
            <svg width="40" height="33" viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M34 7.50006H32.4L21.4 1.22006C19.26 5.88298e-05 16.54 0.360059 14.78 2.10006L9.38 7.50006H8C4.96 7.50006 2.5 9.96006 2.5 13.0001V15.5001H2C1.18 15.5001 0.5 16.1801 0.5 17.0001V23.0001C0.5 23.8201 1.18 24.5001 2 24.5001H2.5V27.0001C2.5 30.0401 4.96 32.5001 8 32.5001H34C37.04 32.5001 39.5 30.0401 39.5 27.0001V13.0001C39.5 9.96006 37.04 7.50006 34 7.50006ZM16.9 4.22006C17.7 3.42006 18.94 3.26006 19.9 3.82006L26.34 7.50006H13.6L16.88 4.22006H16.9ZM3.5 21.5001V18.5001H8.5V21.5001H3.5ZM36.5 27.0001C36.5 28.3801 35.38 29.5001 34 29.5001H8C6.62 29.5001 5.5 28.3801 5.5 27.0001V24.5001H9C10.38 24.5001 11.5 23.3801 11.5 22.0001V18.0001C11.5 16.6201 10.38 15.5001 9 15.5001H5.5V13.0001C5.5 11.6201 6.62 10.5001 8 10.5001H34C35.38 10.5001 36.5 11.6201 36.5 13.0001V27.0001Z"
                fill="#FEA700" />
            </svg>
            <b-card-title>
              Salderingsregeling
            </b-card-title>
          </b-card>
        </b-col>
        <b-col class="col-12 col-lg-5 financials__right">
          <img class="d-none d-lg-block" src="../assets/financials.png" />
          <img class="d-block d-lg-none" src="../assets/financial_sm.png" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Financials',
  data() {
    return {
      cardPoints: []
    };
  },
}
</script>

<style lang="scss" scoped>
.financials {
  padding: 3rem 0;

  &__left {
    .heading {
      color: #171734;
      font-size: 51px;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: 0px;
      text-align: left;
    }

    .paragraph {
      color: #171734;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;

      span {
        font-weight: 700;
      }
    }

    .heading_2 {
      color: #333333;
      margin-top: 3rem;
      font-size: 30px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;

    }

    .financial_card {
      margin-top: 1.5rem;
      border: 1px solid #EEEEEE;
      box-shadow: 18px 15px 35px 0px #00000017;


      .card-body {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          margin: 0 1rem;
        }

        .card-title {
          margin-bottom: 0;
          color: #333333;
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: left;

        }
      }
    }
  }

  &__right {
    img {
      width: 80%;
    }
  }

  @media (min-width: 1440px) {
    &__right {
      img {
        width: 65%;
      }
    }
  }

  @media (max-width: 1199px) {
    &__row {
      align-items: center;
    }

    &__left {
      .heading {
        font-size: 47px;
      }

      .heading_2 {
        font-size: 30px;
      }

      .financial_card .card-body .card-title {
        font-size: 20px;
      }
    }

    &__right {
      img {
        width: 100%;
      }
    }
  }

  @media (max-width:992px) {
    padding-top: 0;

    .container {
      max-width: 100%;

    }

    &__left {
      width: 70% !important;
    }

    &__right {
      padding: 0;

      img {
        margin-bottom: 3rem;
      }
    }

  }

  @media (max-width: 575px) {
    &__left {
      width: 100% !important;

      .heading {
        font-size: 30px;
        line-height: 42px;
      }

      .heading_2 {
        font-size: 25px;
        margin-top: 2rem;
      }

      .financial_card .card-body .card-title {
        font-size: 16px;
        line-height: 20px;
      }
    }

  }

  @media (max-width: 340px) {
    &__left {
      .heading {
        font-size: 25px;
      }

      .heading_2 {
        font-size: 25px;
        margin-top: 2rem;
      }
    }

  }
}
</style>