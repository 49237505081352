<template>
  <b-container class="main-cont p-0">
    <b-form class="input__form" v-if="!details.form.leadResponse">
      <h2 class="title mb-3">
        Ontvang nu gratis en vrijblijvend 5 offertes en profiteer van 40% extra
        voordeel!
      </h2>
      <div class="row my-4 mx-0">
        <div class="col-lg-6 mb-2 p-0">
          <b-form-group
            id="Postcode"
            class="input__form-label"
            label="Voornaam"
            label-for="Voornaam"
          >
            <b-form-input
              v-model="details.form.firstName"
              class="input__form-field"
              :class="{
                'is-valid': fnameCheck && details.form.firstName !== '',
                'is-invalid': !fnameCheck,
              }"
              type="text"
              required
              @input="validate('fname')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!fnameCheck">
              Voornaam niet correct</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="col-lg-6 mb-2 p-0">
          <b-form-group
            id="Postcode"
            class="input__form-label"
            label="Achternaam"
            label-for="Achternaam"
          >
            <b-form-input
              v-model="details.form.lastName"
              class="input__form-field"
              :class="{
                'is-valid': lNameCheck && details.form.lastName !== '',
                'is-invalid': !lNameCheck,
              }"
              type="text"
              required
              @input="validate('lname')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!lNameCheck">
              Achternaam niet correct</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="col-lg-6 mb-2 p-0">
          <b-form-group
            id="Postcode"
            class="input__form-label"
            label="E-mailadres"
            label-for="Emailadres"
          >
            <b-form-input
              v-model="details.form.email"
              class="input__form-field"
              :class="{
                'is-valid': emailCheck && details.form.email !== '',
                'is-invalid': !emailCheck,
              }"
              type="email"
              required
              @input="validate('email')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!emailCheck"
              >E-mailadres niet correct</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="col-lg-6 mb-2 p-0">
          <b-form-group
            id="Telefoonnummer"
            class="input__form-label"
            label="Telefoonnummer"
            label-for="Telefoonnummer"
          >
            <b-form-input
              v-model="details.form.phone_number"
              class="input__form-field"
              :class="{
                'is-valid': phoneCheck && details.form.phone_number !== '',
                'is-invalid': !phoneCheck,
              }"
              type="text"
              required
              @input="validate('phone')"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!phoneCheck"
              >Telefoonnummer niet correct</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <!-- <div class="col-lg-6 mb-2 p-0">
          <b-form-group id="Telefoonnummer" class="input__form-label" label="Postcode" label-for="Postcode">
            <b-form-input v-model="details.firstQuestion.zip" class="input__form-field text-uppercase"
              :class="{ 'is-valid': zipCheck && details.firstQuestion.zip !== '', 'is-invalid': !zipCheck }"
              type="email" required @input="validate('zip')"></b-form-input>
          </b-form-group>
          <b-form-invalid-feedback v-if="!houseCheck">Postcode niet
            correct</b-form-invalid-feedback>

        </div>
        <div class="col-lg-6 mb-2 p-0">

          <b-form-group id="Huisnummer" class="input__form-label" label="Huisnummer" label-for="Huisnummer">
            <b-form-input v-model="details.firstQuestion.house_number" class="input__form-field "
              :class="{ 'is-valid': houseCheck && details.firstQuestion.house_number !== '', 'is-invalid': !houseCheck }"
              type="email" required @input="validate('house')"></b-form-input>
            <b-form-invalid-feedback v-if="!houseCheck">Huisnummer niet
              correct</b-form-invalid-feedback>
          </b-form-group>

        </div> -->
        <div class="mt-4 mx-0 px-0">
          <b-button
            class="input__form-btn"
            @click="submitForm"
            :disabled="getLoader"
          >
            <b-spinner small v-if="getLoader"></b-spinner>
            Afronden
            <span
              ><svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46973 3.53029L10.9395 9.00004L5.46973 14.4698L6.53023 15.5303L13.0605 9.00004L6.53023 2.46979L5.46973 3.53029Z"
                  fill="white"
                />
              </svg>
            </span>
          </b-button>
        </div>
        <div class="mt-4 mx-0 px-0 d-none">
          <b-form-checkbox id="checkbox-1" v-model="bootKey" name="checkbox-1">
          </b-form-checkbox>
        </div>
        <div class="mt-4 mx-0 px-0">
          <p class="input__form-label result-label">
            Met jouw gegevens gaan wij zorgvuldig om. Jouw gegevens worden
            uitsluitend gebruikt om jou te voorzien van 5 offertes van partners
            (installateurs) die bij ons zijn aangesloten. Dit is volledig gratis
            en vrijblijvend, je zit nergens aan vast!
          </p>
        </div>
      </div>
    </b-form>
    <b-form class="input__form" v-else>
      <h2 class="title text-center">Bedankt!</h2>
      <img
        v-if="profId"
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://republish.verbeterthuis.nl/m/5774/646ea0ad92f0/?event=6737&unique_conversion_id=${profId}`"
        style="width: 1px; height: 1px; border: 0px"
      />
      <p class="mt-4">
        <strong>Wij hebben jouw aanvraag correct ontvangen!</strong>
      </p>
      <p class="mt-4">
        <strong
          >Jij ontvangt z.s.m. 5 offertes van erkende installateurs.
        </strong>
      </p>
    </b-form>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ResultForm",
  props: {
    step: {
      type: Number,
    },
    details: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["address", "getLoader", "profId"]),
  },
  watch: {
    bootKey(newVal) {
      console.log("Checkbox value changed:", newVal);
    },
    "details.form.leadResponse": function (newVal, oldVal) {
      if (newVal) {
        this.setupFacebookPixel();
      }
    },
  },
  data() {
    return {
      phoneRegex: /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/,
      nameRegex: /^[a-zA-Z\s.,'-]{1,30}$/,
      emailRegex: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/,
      // zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      // houseRegex: /^[0-9]+$/,
      fnameCheck: true,
      lNameCheck: true,
      phoneCheck: true,
      emailCheck: true,
      zipCheck: true,
      houseCheck: true,
      bootKey: false,
    };
  },

  methods: {
    ...mapActions(["postLead"]),
    validate(key) {
      if (key == "fname") {
        this.fnameCheck = this.nameRegex.test(this.details.form.firstName);
      } else if (key == "lname") {
        this.lNameCheck = this.nameRegex.test(this.details.form.lastName);
      } else if (key == "email") {
        this.emailCheck = this.emailRegex.test(this.details.form.email);
      } else if (key == "phone") {
        this.phoneCheck = this.phoneRegex.test(this.details.form.phone_number);
      } else if (key == "zip") {
        this.zipCheck = this.zipRegex.test(this.details.firstQuestion.zip);
      } else {
        this.houseCheck = this.houseRegex.test(
          this.details.firstQuestion.house_number
        );
      }
    },
    submitForm() {
      // console.log("submitted form", this.details)
      let answers = [
        "5005",
        this.details.secondQuestion,
        this.details.thirdQuestion,
        this.details.fourthQuestion,
        this.details.fifthQuestion,
        this.details.sixthQuestion,
      ];
      let payload = {
        bootkey: this.bootKey,
        language: "nl_NL",
        site_custom_name: "offertenl_zonnepanelen",
        site_custom_url: "https://offerte.verbeterthuis.nl/zonnepanelen",
        firstname: this.details.form.firstName,
        lastname: this.details.form.lastName,
        email: this.details.form.email,
        street: this.address?.street || "",
        city: this.address?.settlement || "",
        house_number: this.details?.firstQuestion?.house_number,
        zip: this.details.firstQuestion.zip,
        phone_number: this.details.form.phone_number,
        answers: answers,
        site_subid: this.$route.query.clickid || "",
        publisher_id: this.$route.query.site || "",
      };
      const phoneNumberWithoutPunctuation = this.details.form.phone_number
        .replace(/[\s-]/g, "")
        .replace(/^00(\d+)$/, "$1");
      payload.phone_number = phoneNumberWithoutPunctuation;
      this.postLead({
        payload,
        phone_number: phoneNumberWithoutPunctuation,

        onSuccess: () => {
          this.details.form.leadResponse = true;
          // this.$emit("nextStep", this.step + 1);
        },
        onError: (res) => {
          let { response } = res;
          console.log(res, "response error");
          if (response.status == 409) {
            this.details.form.leadResponse = true;
            // this.$emit("nextStep", this.step + 1); // duplicate
          } else if (response.data.code == 400) {
            if (
              response.data.error ==
              "Bad Request - Validation error for 'phone_number'"
            ) {
              this.phoneCheck = false;
            } else if (
              response.data.error ==
              "Bad Request - Validation error for 'house_number'"
            ) {
              this.houseCheck = false;
              this.$emit("setAddress", "house");
            } else if (
              response.data.error ==
              "Bad Request - Validation error for 'lastname'"
            ) {
              this.lNameCheck = false;
            } else if (
              response.data.error ==
              "Bad Request - Validation error for 'firstname'"
            ) {
              this.fnameCheck = false;
            } else if (
              response.data.error ==
              "Bad Request - Validation error for 'email'"
            ) {
              this.emailCheck = false;
            } else if (
              response.data.error == "Bad Request - Validation error for 'zip'"
            ) {
              this.zipCheck = false;
              this.$emit("setAddress", "post");
            } else {
              alert(response.data.error);
            }
          }
        },
      });
    },
    setupFacebookPixel() {
      let useScript = document.createElement("script");
      useScript.setAttribute(
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";

          n.queue = [];
          t = b.createElement(e);
          t.async = !0;

          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        ),
        fbq("init", "1139857983706927"),
        fbq("track", "PageView"),
        fbq("track", "Lead")
      ),
        document.head.appendChild(useScript);
    },
  },
  created() {
    document.title = "Offerte Zonnepabelen";
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=1139857983706927&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>
<style lang="scss" scoped>
.main-cont {
  min-height: 50vh;
}

.input__form {
  text-align: center;
  padding: 1rem 2rem;

  .result-label {
    color: #000b29 !important;
  }

  .title {
    text-align: center;
    font-size: 30px;
    line-height: 35px;
  }

  .row {
    text-align: start;
  }

  @media (max-width: 768px) {
    .title {
      text-align: start;
      font-size: 25px;
      line-height: 35px;
    }
  }

  @media (max-width: 450px) {
    padding: 0;

    &-field {
      width: 100% !important;
    }
  }

  @media (max-width: 390px) {
    .title {
      font-size: 20px;
      line-height: 25px;
    }
  }

  @media (max-width: 332px) {
    .title {
      font-size: 18px;
      line-height: 27px;
    }
  }
}
</style>
