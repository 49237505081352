var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"main-cont mb-3"},[(_vm.firstQuestion.show)?_c('div',[_c('b-form',{staticClass:"input__form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('BackButton',{on:{"back":_vm.goBack}}),_c('p',{staticClass:"mb-0 vraag"},[_vm._v("Vraag "+_vm._s(_vm.step)+" van 6")]),_c('h2',{staticClass:"title"},[_vm._v("Wat is jouw adres?")]),_c('div',{staticClass:"row my-4 mx-0"},[_c('div',{staticClass:"col-lg-5 p-0 mb-3 mb-lg-0"},[_c('b-form-group',{staticClass:"input__form-label",attrs:{"id":"Postcode","label":"Postcode","label-for":"Postcode"}},[_c('b-form-input',{staticClass:"input__form-field text-uppercase",class:{
                'is-valid': _vm.validationCheck && _vm.firstQuestion.zip !== '',
                'is-invalid': !_vm.validationCheck,
              },attrs:{"placeholder":"3822 VJ","type":"text","required":""},on:{"input":function($event){return _vm.validate('zip')}},model:{value:(_vm.firstQuestion.zip),callback:function ($$v) {_vm.$set(_vm.firstQuestion, "zip", $$v)},expression:"firstQuestion.zip"}}),(!_vm.validationCheck)?_c('b-form-invalid-feedback',[_vm._v("Moet minimaal 4 numerieke tekens en 2 tekens bevatten. postcode niet correct")]):_vm._e()],1),(_vm.setAddress)?_c('div',{},[_c('p',{staticClass:"text-danger",attrs:{"show":"","dismissible":""}},[_vm._v("adres is niet correct")])]):_vm._e()],1),_c('div',{staticClass:"col-lg-5 p-0"},[_c('b-form-group',{staticClass:"input__form-label",attrs:{"id":"Huisnummer","label":"Huisnummer","label-for":"Postcode"}},[_c('b-form-input',{staticClass:"input__form-field text-uppercase",class:{
                'is-valid':
                  _vm.invalidAddress && _vm.firstQuestion.house_number !== '',
                'is-invalid': !_vm.invalidAddress,
              },attrs:{"required":"","placeholder":"6","type":"text"},on:{"input":function($event){return _vm.validate('house')}},model:{value:(_vm.firstQuestion.house_number),callback:function ($$v) {_vm.$set(_vm.firstQuestion, "house_number", $$v)},expression:"firstQuestion.house_number"}}),(!_vm.invalidAddress)?_c('b-form-invalid-feedback',[_vm._v("Enter a valid House Number")]):_vm._e()],1)],1),(_vm.loader)?_c('div',{staticClass:"col-lg-1 mt-4 p-0 d-flex justify-content-center align-items-center"},[_c('b-spinner',{attrs:{"small":"","variant":"primary"}})],1):_vm._e()]),_c('div',{staticClass:"mt-2"},[_c('b-button',{staticClass:"input__form-btn",attrs:{"type":"submit","disabled":_vm.loader}},[_vm._v(" Ophalen woninggegevens "),_c('span',[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.46973 3.53029L10.9395 9.00004L5.46973 14.4698L6.53023 15.5303L13.0605 9.00004L6.53023 2.46979L5.46973 3.53029Z","fill":"white"}})])])])],1)],1)],1):_c('div',[_c('div',{staticClass:"input__form"},[_c('h2',{staticClass:"title"},[_vm._v("Jouw adres")]),_c('div',{staticClass:"row my-4 mx-0"},[_c('div',{staticClass:"col-12 p-0"},[_c('p',{staticClass:"input__form-label"},[_vm._v(" "+_vm._s(this.address.street + " " + this.address.streetNumber + " " + this.address.premise + ", " + this.address.postalCode + " in " + this.address.settlement)+" ")]),_c('GoogleMapComponent')],1)]),_c('div',[_c('b-button',{staticClass:"input__form-btn",on:{"click":_vm.goToNextStep}},[_vm._v(" Volgende "),_c('span',[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.46973 3.53029L10.9395 9.00004L5.46973 14.4698L6.53023 15.5303L13.0605 9.00004L6.53023 2.46979L5.46973 3.53029Z","fill":"white"}})])])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }